export const MenuItemIds = {
  OrdersGroupItemId: 'orders-group',
  OrdersNavItemId: 'orders-page',
  CreateOrderNavItemId: 'create-sales-order',
  OrderDraftsNavItemId: 'sales-order-drafts',
  OrderImportNavItemId: 'order-import',
  DeliveriesNavItemId: 'deliveries-page',
  InvoicesNavItemId: 'invoices-page',
  ReturnsNavItemId: 'returns-page',
  HomePageNavItemId: 'home-page',
  AdminGroupItemId: 'admin-group',
  TeamAdminPageNavItemId: 'team-admin-page',
  AccountAdminPageNavItemId: 'account-admin-page',
  IntegrationsGroupItemId: 'integrations-group',
  IntegrationsFeedPageNavItemId: 'integrations-feeds-page',
  IntegrationsPublicRestApiId: 'integrations-rest-api',
  IntegrationsPublicRestApiDocsId: 'integrations-rest-api-docs',
  IntegrationsPublicRestApiKeysId: 'integrations-file-api-keys',
  IntegrationsPublicFileApiId: 'integrations-file-api',
  IntegrationsPublicFileApiDocsId: 'integrations-file-api-docs',
  IntegrationsPublicFileApiSftpId: 'integrations-file-sftp-details',
  IntegrationsInboundPageNavItemId: 'integrations-inbounds-page',
  IntegrationsOutboundPageNavItemId: 'integrations-outbounds-page',
  IntegrationsEventsPageNavItemId: 'integrations-events-page',
  CatalogueGroupItemId: 'catalogue-group',
  CatalogueProductsNavItemId: 'product-stocks-page',
  CataloguePromotionsNavItemId: 'product-promotions-page',
  CatalogueMarketingNavItemId: 'marketing-page',
  AnalyticsGroupItemId: 'analytics-group',
  AnalyticsReportsNavItemId: 'reports-page'
};
