// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  NotificationImportantOutlined,
  ForkLeftOutlined,
  ForkRightOutlined,
  ImportExportOutlined,
  InsertDriveFileOutlined,
  KeyOutlined,
  FolderOutlined,
  UploadFileOutlined
} from '@mui/icons-material';

// type
import { NavItemType } from 'types/menu';
import { AccountPermissionLevel, AccountType } from 'types/account';
import { MenuItemIds } from './menu-item-ids';
import Routes from 'routes/RouteNames';
import { INTEGRATION_FEEDS_FEATURE } from 'featureConstants';

// icons
const icons = {
  NotificationImportantOutlined,
  ForkLeftOutlined,
  ForkRightOutlined,
  ImportExportOutlined,
  InsertDriveFileOutlined,
  KeyOutlined,
  FolderOutlined,
  UploadFileOutlined
};

const integrations: NavItemType = {
  id: MenuItemIds.IntegrationsGroupItemId,
  title: <FormattedMessage id={MenuItemIds.IntegrationsGroupItemId} />,
  type: 'group',
  children: [
    {
      id: MenuItemIds.IntegrationsPublicRestApiId,
      title: <FormattedMessage id={MenuItemIds.IntegrationsPublicRestApiId} />,
      type: 'collapse',
      underConstruction: [AccountType.Supplier],
      icon: icons.ForkLeftOutlined,
      breadcrumbs: false,
      requiredAccountRole: AccountPermissionLevel.Admin,
      accountType: AccountType.Customer,
      children: [
        {
          id: MenuItemIds.IntegrationsPublicRestApiDocsId,
          title: <FormattedMessage id={MenuItemIds.IntegrationsPublicRestApiDocsId} />,
          type: 'item',
          url: window.__RUNTIME_CONFIG__.REACT_APP_PUBLIC_API_DOCS_URL,
          external: true,
          target: true,
          underConstruction: [AccountType.Supplier],
          icon: icons.InsertDriveFileOutlined,
          breadcrumbs: false,
          accountType: AccountType.Customer
        },
        {
          id: MenuItemIds.IntegrationsPublicRestApiKeysId,
          title: <FormattedMessage id={MenuItemIds.IntegrationsPublicRestApiKeysId} />,
          type: 'item',
          url: `/${Routes.RestApiKeys}`,
          underConstruction: [AccountType.Supplier],
          icon: icons.KeyOutlined,
          breadcrumbs: false,
          accountType: AccountType.Customer
        }
      ]
    },
    {
      id: MenuItemIds.IntegrationsPublicFileApiId,
      title: <FormattedMessage id={MenuItemIds.IntegrationsPublicFileApiId} />,
      type: 'collapse',
      underConstruction: [AccountType.Supplier],
      icon: icons.FolderOutlined,
      breadcrumbs: false,
      requiredAccountRole: AccountPermissionLevel.Admin,
      accountType: AccountType.Customer,
      children: [
        {
          id: MenuItemIds.IntegrationsPublicFileApiDocsId,
          title: <FormattedMessage id={MenuItemIds.IntegrationsPublicFileApiDocsId} />,
          type: 'item',
          url: window.__RUNTIME_CONFIG__.REACT_APP_FILE_API_DOCS_URL,
          external: true,
          target: true,
          underConstruction: [AccountType.Supplier],
          icon: icons.InsertDriveFileOutlined,
          breadcrumbs: false,
          accountType: AccountType.Customer
        },
        {
          id: MenuItemIds.IntegrationsPublicFileApiSftpId,
          title: <FormattedMessage id={MenuItemIds.IntegrationsPublicFileApiSftpId} />,
          type: 'item',
          url: `/${Routes.FileSftpDetails}`,
          underConstruction: [AccountType.Supplier],
          icon: icons.UploadFileOutlined,
          breadcrumbs: false,
          accountType: AccountType.Customer
        }
      ]
    },
    {
      id: MenuItemIds.IntegrationsFeedPageNavItemId,
      title: <FormattedMessage id={MenuItemIds.IntegrationsFeedPageNavItemId} />,
      type: 'item',
      url: `/${Routes.Feeds}`,
      icon: icons.ImportExportOutlined,
      underConstruction: [AccountType.Customer, AccountType.Supplier],
      breadcrumbs: false,
      featureFlag: INTEGRATION_FEEDS_FEATURE,
      accountType: AccountType.Customer
    },
    {
      id: MenuItemIds.IntegrationsInboundPageNavItemId,
      title: <FormattedMessage id={MenuItemIds.IntegrationsInboundPageNavItemId} />,
      type: 'item',
      url: `/${Routes.InboundIntegrations}`,
      icon: icons.ForkRightOutlined,
      underConstruction: [AccountType.Customer, AccountType.Supplier],
      breadcrumbs: false,
      featureFlag: INTEGRATION_FEEDS_FEATURE,
      accountType: AccountType.Customer
    },
    {
      id: MenuItemIds.IntegrationsOutboundPageNavItemId,
      title: <FormattedMessage id={MenuItemIds.IntegrationsOutboundPageNavItemId} />,
      type: 'item',
      url: `/${Routes.OutboundIntegrations}`,
      icon: icons.ForkLeftOutlined,
      underConstruction: [AccountType.Customer, AccountType.Supplier],
      breadcrumbs: false,
      featureFlag: INTEGRATION_FEEDS_FEATURE,
      accountType: AccountType.Customer
    },
    {
      id: MenuItemIds.IntegrationsEventsPageNavItemId,
      title: <FormattedMessage id={MenuItemIds.IntegrationsEventsPageNavItemId} />,
      type: 'item',
      url: `/${Routes.IntegrationEvents}`,
      icon: icons.NotificationImportantOutlined,
      underConstruction: [AccountType.Customer, AccountType.Supplier],
      breadcrumbs: false,
      featureFlag: INTEGRATION_FEEDS_FEATURE,
      accountType: AccountType.Customer
    }
  ]
};

export default integrations;
